<template>
    <div id="exercise">

        <div class="card">
            <div class="card-body">
                <div class="p-1">
                    <a href="#" type="button" data-toggle="modal" data-target="#modalConfirmDeliver" class="btn btn-primary btn-rounded float-right" v-if="(computeUrlType() !== urlTypeEnum.EXERCISE && currentQuizMeta.tipo_esercitazione !== exerciseEnum.RIVEDI_PROVA)">
                        <i class="mdi mdi-send"></i> {{$t('exercise.consegna')}}
                    </a>

                    <a href="#" type="button" data-toggle="modal" data-target="#modalConfirm" :class="[((computeUrlType() !== urlTypeEnum.EXERCISE && currentQuizMeta.tipo_esercitazione !== exerciseEnum.RIVEDI_PROVA) ? 'mr-2' : ''), 'btn btn-danger btn-rounded float-right']">
                        <i class="mdi mdi-location-exit"></i> {{$t('generale.esci')}}
                    </a>

                    <div class="d-inline pr-2">
                        <span class="card-title mt-0 h3" v-if="isPath">{{$t('exercise.sottoArgomento')}}:</span>
                        <span class="card-title mt-0 h3" v-else>{{$t('exercise.materia')}}:</span>
                    </div>
                    <div class="d-inline">
                        <span class="card-title mt-0 h3">
                            <template v-if="isLoading">
                                <ContentLoader
                                    :speed="2"
                                    :width="100"
                                    :height="15"
                                    style="width: 20%!important; display: inline-block"
                                    :animate="animated"
                                    :primaryColor="computeColor('primary')"
                                    :secondaryColor="computeColor('secondary')"
                                >
                                    <rect x="0" y="0" rx="1" ry="1" width="75" height="11" />
                                </ContentLoader>
                            </template>
                            <span class="badge badge-info align-middle" v-else>
                                <span v-if="isPath">{{currentQuiz.sotto_percorso}}</span>
                                <span v-else>{{currentQuiz.materia_uff}}</span>
                            </span>
                        </span>
                    </div>

                    <div class="pt-1">
                        <span class="font-weight-bolder" v-if="isPath">{{$t('exercise.materia')}}:</span>
                        <span class="font-weight-bolder" v-else>{{$t('exercise.bancaDati')}}:</span>
                        <span class="pl-1" v-if="isPath">{{currentQuiz.argomento}}</span>
                        <span class="pl-1" v-else>{{currentQuiz.concorso}}</span>
                    </div>

                    <div class="d-flex">
                        <h4 :class="['font-size-18 mb-3 mt-1', showAutoNext ? 'w-50' : 'w-100'].join(' ')">
                            <template v-if="(computeUrlType() === urlTypeEnum.EXERCISE && currentQuizMeta.modalita_esercitazione !== exerciseMode.LETTA && currentQuizMeta.modalita_esercitazione !== exerciseMode.ASCOLTATA && currentQuizMeta.tipo_esercitazione !== exerciseEnum.ASSEGNA_RISPOSTE)">
                                <span class="text-success">✔{{correctAnswers}}</span> / <span class="text-danger">✘{{wrongAnswers}}</span>
                            </template>
                            <span class="mb-3 mt-0 float-right" v-if="(computeUrlType() !== urlTypeEnum.EXERCISE && currentQuizMeta.tipo_esercitazione !== exerciseEnum.RIVEDI_PROVA)">
                                <i class="mdi mdi-alarm mr-2 mdi-18px align-middle"></i>
                                <span class="badge badge-soft-primary" style="font-size: 20px; vertical-align: top">{{ countdownTimer }}</span>
                                <button type="button" class="btn btn-primary btn-sm ml-2" @click="pauseTimer()"><i class="mdi mdi-pause"></i></button>
                            </span>
                        </h4>

                        <div class="ml-auto" v-if="showAutoNext">
                            <div class="custom-control custom-checkbox pt-2">
                                <input type="checkbox" class="custom-control-input" id="autoNextCheck" v-model="autoNext">
                                <label style="font-size:11px" class="custom-control-label pt-1" for="autoNextCheck">
                                    {{$t('exercise.autoNext')}}
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="card">
            <div :class="[((isPaused) ? 'blurred' : ''), 'card-body']">
                <template v-if="isLoading">
                    <ContentLoader
                        :speed="2"
                        :width="1130"
                        :height="420"
                        :animate="animated"
                        :primaryColor="computeColor('primary')"
                        :secondaryColor="computeColor('secondary')"
                    >
                        <rect x="16" y="5" rx="2" ry="2" width="1090" height="30" />
                        <rect x="340" y="120" rx="2" ry="2" width="460" height="260" />
                        <rect x="16" y="45" rx="2" ry="2" width="1090" height="30" />
                    </ContentLoader>

                    <ContentLoader
                        :speed="2"
                        :width="400"
                        :height="75"
                        :animate="animated"
                        :primaryColor="computeColor('primary')"
                        :secondaryColor="computeColor('secondary')"
                    >
                        <circle cx="10" cy="10" r="3"></circle>
                        <rect x="16" y="7" rx="1" ry="1" width="380" height="6"></rect>

                        <circle cx="10" cy="37" r="3"></circle>
                        <rect x="16" y="34" rx="1" ry="1" width="380" height="6"></rect>

                        <circle cx="10" cy="64" r="3"></circle>
                        <rect x="16" y="61" rx="1" ry="1" width="380" height="6"></rect>
                    </ContentLoader>
                </template>
                <div id="quiz-content" style="visibility: hidden" v-if="quizError === false">
                    <div id="overlay"></div>
                    <div class="row">
                        <div :class="[((computeUrlType() === urlTypeEnum.EXERCISE || currentQuizMeta.tipo_esercitazione === exerciseEnum.RIVEDI_PROVA) ? 'col-md-11' : 'col-md-12'), 'my-auto']">
                            <h3 class="pl-3 text-primary">
                                {{currentQuiz.domanda}}
                            </h3>
                        </div>
                        <div class="col-1 text-right">
                            <div class="pr-3">
                                <button type="button" data-toggle="tooltip" @click="addFavorites()" data-placement="right" v-if="computeUrlType() === urlTypeEnum.EXERCISE || currentQuizMeta.tipo_esercitazione === exerciseEnum.ASSEGNA_RISPOSTE"
                                        :title="$t('exercise.buttons.favorite')"
                                        class="btn btn-outline-primary btn-rounded btn-sm waves-effect waves-light mb-3" :style="(currentQuiz.preferiti) ? 'color: #fff; background-color: #3051d3; border-color: #3051d3;' : ''">
                                    <i class="mdi mdi-cards-heart mdi-18px"></i>
                                </button>
                                <span v-if="(computeUrlType() === urlTypeEnum.EXERCISE || currentQuizMeta.tipo_esercitazione === exerciseEnum.RIVEDI_PROVA) && currentQuiz.commento !== null && currentQuiz.commento !== '' && currentQuiz.commento !== undefined">
                                    <br><button type="button" @click="openExplanation(currentQuiz.commento)" data-toggle="tooltip" data-placement="right" :title="$t('exercise.buttons.explanation')" class="btn btn-outline-primary btn-rounded btn-sm waves-effect waves-light mb-3"> <i class="mdi mdi-teach mdi-18px"></i></button>
                                </span>
                                <template v-if="computeUrlType() === urlTypeEnum.EXERCISE && currentQuiz.url_wiki !== null && currentQuiz.url_wiki !== '' && currentQuiz.url_wiki !== undefined">
                                    <br><button type="button" @click="openWiki()" data-toggle="tooltip" data-placement="right" :title="$t('exercise.buttons.wikipedia')" class="btn btn-warning btn-rounded btn-sm waves-effect waves-light mb-3"> <i class="mdi mdi-wikipedia mdi-18px"></i></button>
                                    <br>
                                </template>
                                <button v-if="computeUrlType() === urlTypeEnum.EXERCISE || currentQuizMeta.tipo_esercitazione === exerciseEnum.RIVEDI_PROVA" type="button" data-toggle="tooltip" data-placement="right" :title="$t('exercise.buttons.info')" @click="openModalReport()" class="btn btn-info btn-rounded btn-sm waves-effect waves-light"> <i class="mdi mdi-information-variant mdi-18px"></i></button>
                            </div>
                        </div>
                        <div class="col-md-12 text-center mt-3" v-if="currentQuiz.url_img !== '' && currentQuiz.url_img !== null && currentQuiz.url_img !== undefined && isImageError === false && isImageLoaded">
                            <div id="image-container" class="overflow-hidden">
                                <div class="position-absolute p-2" style="right: 0;">
                                    <div class="overlay-image-btn d-inline p-2">
                                        <button class="btn btn-primary btn-rounded btn-sm waves-effect waves-light btn-sm" @click="openImageQuiz()" style="z-index: 999">
                                            <i class="mdi mdi-magnify-remove-outline font-size-22"></i>
                                        </button>
                                    </div>
                                    <div class="d-inline p-2">
                                        <button class="btn btn-primary btn-rounded btn-sm waves-effect waves-light btn-sm" @click="resetPanZoom" style="z-index: 999">
                                            <i class="mdi mdi-refresh font-size-22"></i>
                                        </button>
                                    </div>
                                </div>
                                <img class="img-fluid" alt="" :src="currentQuiz.url_img" style="z-index: 1; max-height: 410px!important; visibility: hidden;">
                            </div>
                        </div>
                        <div class="col-md-12 text-center mt-3" v-else-if="isImageError === true">
                            <img class="img-fluid h-100" alt="" src="../assets/images/errore.png" style="z-index: 1; max-height: 410px!important;">
                        </div>
                    </div>

                    <div class="mt-5 px-3 text-center h3" v-if="isNotAnswered">
                        <span class="font-weight-bolder text-white badge badge-secondary">{{$t('exercise.domandaNonRisposta')}}</span>
                    </div>

                    <div :class="[((isNotAnswered) ? 'mt-1' : 'mt-5'), 'ml-3 pr-3']">
                        <template v-for="(risposta, index) in currentQuiz.risposte">
                            <div v-if="risposta.esatta && currentQuizMeta.modalita_esercitazione === exerciseMode.ASCOLTATA">
                                <div class="pt-3 pb-3 pr-3">
                                    <div class="row align-items-center">
                                        <div class="col-md-12">
                                            <label class="d-inline">
                                                {{ risposta.testo }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="currentQuizMeta.modalita_esercitazione !== exerciseMode.ASCOLTATA">
                                <div :class="[(computeExerciseMode() ? 'cursorNotAllowed' : ''), computeTheAnswer(risposta.id, risposta.esatta), (computeTheModeAndTheAnswer() ? '' : 'cursorPointer'), 'custom-control custom-radio pt-3 pb-3 pr-3']" @click.prevent="selectAnswer(risposta.id, risposta.esatta)">
                                    <div class="row align-items-center pl-3">
                                        <div class="col-md-11">
                                            <input type="radio" :id="'customRadio' + index" name="customRadio" class="custom-control-input d-inline" v-model="answer" :value="risposta.id" :disabled="computeTheModeAndTheAnswer()">
                                            <label :class="[(computeExerciseMode() ? 'cursorNotAllowed' : ''), 'custom-control-label d-inline', (computeTheModeAndTheAnswer() ? '' : 'cursorPointer')]" :for="'customRadio' + index">
                                                {{ risposta.testo }}
                                            </label>
                                        </div>
                                        <div class="col-md-1">
                                            <template v-if="computeUrlType() === urlTypeEnum.EXERCISE || currentQuizMeta.tipo_esercitazione === exerciseEnum.RIVEDI_PROVA">
                                                <span v-if="answered && risposta.esatta" class="badge badge-soft-primary float-right text-uppercase">{{$t('exercise.corretta')}}</span>
                                                <span v-if="answered && answer === risposta.id && (risposta.esatta === false)" class="badge badge-soft-danger float-right text-uppercase">{{$t('exercise.errata')}}</span>
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div style="padding-left: 1.1rem; font-size: 11px; text-align: right" v-if="currentQuizMeta.tipo_esercitazione === exerciseEnum.ASSEGNA_RISPOSTE">
                                    <span v-if="popularAnswers !== undefined && popularAnswers['risposta' + risposta.id] !== undefined && popularAnswers['risposta' + risposta.id] !== null && popularAnswers['risposta' + risposta.id] !== ''">{{$t('exercise.utentiCheHannoSceltoComeEsatta')}}: {{popularAnswers['risposta' + risposta.id]}}</span>
                                    <span v-else>{{$t('exercise.datiStatisticiNonAncoraDisponibili')}}</span>
                                </div>

                                <hr v-if="index !== (currentQuiz.risposte.length - 1)" />
                            </div>
                        </template>
                    </div>
                </div>
                <div class="text-center" v-else>
                    <button type="button" data-toggle="tooltip" data-placement="right" :title="$t('exercise.buttons.info')" @click="openModalReport()" class="btn btn-info btn-rounded btn-sm waves-effect waves-light"> <i class="mdi mdi-information-variant mdi-18px"></i></button>
                    <div class="w-100 pt-2"><h3>{{$t('exercise.inviaSegnalazione')}}</h3></div>
                </div>

                <div class="row mt-3 py-3" id="footer-container">
                    <div class="col-12 text-center" v-if="currentQuizMeta.modalita_esercitazione === exerciseMode.ASCOLTATA">
                        <h3>{{questionNumber}} / <span class="font-size-16">{{currentQuizMeta.n_quiz}}</span></h3>
                    </div>
                    <div class="col-4">
                        <button type="button" v-if="questionNumber !== 1" class="btn btn-secondary btn-rounded waves-effect waves-light" @click="updateProgress('backward')">
                            <i class="mdi mdi-arrow-left"></i> {{$t('generale.indietro')}}
                        </button>
                    </div>
                    <div class="col-4 text-center" v-if="currentQuizMeta.modalita_esercitazione !== exerciseMode.ASCOLTATA">
                        <h3>{{questionNumber}} / <span class="font-size-16">{{currentQuizMeta.n_quiz}}</span></h3>
                    </div>
                    <div class="col-4 text-center" v-if="currentQuizMeta.modalita_esercitazione === exerciseMode.ASCOLTATA">
                        <button type="button" class="btn btn-primary bg-transparent btn-rounded" @click="speechPauseOrResume()">
                            <i class="mdi mdi-play text-primary" v-if="isSpeechPaused"></i>
                            <i class="mdi mdi-pause text-primary" v-else></i>
                        </button>
                    </div>
                    <div class="col-4 text-sm-right">
                        <button type="button" v-if="currentQuizMeta.n_quiz === questionNumber || currentQuizMeta.n_quiz === 0 || questionNumber > currentQuizMeta.n_quiz || currentQuizMeta.n_quiz === undefined" class="btn btn-primary btn-rounded waves-effect waves-light" @click="goToNextPage()">
                            {{$t('generale.fine')}}
                        </button>
                        <button type="button" class="btn btn-primary btn-rounded waves-effect waves-light" @click="updateProgress('forward')" v-else>
                            {{$t('generale.avanti')}} <i class="mdi mdi-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal limit quiz -->
        <div id="modalLimitQuiz" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLimitQuizLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalLimitQuizLabel">{{$t('exercise.modale.limitQuiz.titolo')}}</h5>
                    </div>
                    <div id="modal-body-limit" class="modal-body">
                        <p id="message-body-limit">{{$t('exercise.modale.limitQuiz.sottotitolo')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.SUBSCRIPTION })">{{$t('exercise.modale.limitQuiz.passaPremium')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal limit quiz -->

        <!-- Modal confirm -->
        <div id="modalConfirm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalConfirmLabel">{{$t('exercise.modale.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-confirm" class="modal-body">
                        <p id="message-body-confirm">{{$t('exercise.modale.sicuroDiVolerUscire')}} {{computeMessageToShow()}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="computeWhereToGo()">{{$t('generale.esci')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal confirm -->

        <!-- Modal autoNext last quiz -->
        <div id="modalAutoNextLastQuiz" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalAutoNextLastQuizLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalAutoNextLastQuizLabel">{{$t('exercise.modale.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-auto-next-last-quiz" class="modal-body">
                        <p id="message-body-auto-next-last-quiz">{{$t('exercise.modale.ultimoQuiz')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="goToNextPage()">{{$t('generale.esci')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal autoNext last quiz -->

        <!-- Modal message -->
        <div id="modalMessage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalMessageLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalMessageLabel">{{$t('exercise.modale.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-message" class="modal-body">
                        <p id="message-body-message">{{""}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="closeModalMessage()">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal message -->

        <!-- Modal confirm deliver -->
        <div id="modalConfirmDeliver" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalConfirmDeliverLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalConfirmDeliverLabel">{{$t('exercise.modale.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-deliver" class="modal-body">
                        <p id="message-body-deliver">{{$t('exercise.modale.consegnaProva')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="goToNextPage()">{{$t('exercise.modale.siConsegna')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal confirm deliver -->

        <!-- Modal exercise error -->
        <div id="modalExerciseError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalExerciseErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalExerciseErrorLabel">{{$t('exercise.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-exercise-error" class="modal-body">
                        <p id="message-body-exercise-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal exercise error -->

        <!-- Modal exercise error blocker -->
        <div id="modalExerciseErrorBlocker" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalExerciseErrorBlockerLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalExerciseErrorBlockerLabel">{{$t('exercise.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-exercise-error-blocker" class="modal-body">
                        <p id="message-body-exercise-error-blocker"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="$router.push({ name: 'Home' })" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal exercise error blocker -->

        <!-- Modal reporting error -->
        <div id="modalReportingError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalReportingErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalReportingErrorLabel">{{$t('exercise.modale.segnala.titolo')}}</h5>
                    </div>
                    <div id="modal-body-reporting-error" class="modal-body">
                        <h5 class="font-size-16">{{$t('exercise.modale.segnala.nonInviata')}}</h5>
                        <p id="message-reporting-body"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal reporting error -->

        <!-- Modal reporting -->
        <div id="modalReporting" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalReportingLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div id="modal-body-reporting" class="modal-body">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <h2 class="card-title mt-0"><b>{{$t('exercise.modale.segnala.dettagli')}}</b></h2>
                                    <table class="table table-centered table-hover mb-0">
                                        <tbody>
                                        <tr>
                                            <th scope="row" width="40%" class="text-right">
                                                {{$t('exercise.modale.segnala.ente')}}
                                            </th>
                                            <th scope="row">
                                                {{ currentQuiz.ente }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-right">
                                                {{$t('exercise.modale.segnala.profilo')}}
                                            </th>
                                            <th scope="row">
                                                {{ currentQuiz.concorso }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-right">
                                                {{$t('exercise.modale.segnala.anno')}}
                                            </th>
                                            <th scope="row">
                                                {{ currentQuiz.anno }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-right">
                                                {{$t('exercise.modale.segnala.materiaUfficiale')}}
                                            </th>
                                            <th scope="row">
                                                {{ currentQuiz.materia_uff }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-right">
                                                {{$t('exercise.modale.segnala.idQuizUfficiale')}}
                                            </th>
                                            <th scope="row">
                                                {{ currentQuiz.indice_uff }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="text-right">
                                                {{$t('exercise.modale.segnala.idQuizInterno')}}
                                            </th>
                                            <th scope="row">
                                                {{ currentQuiz.id }}
                                            </th>
                                        </tr>


                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6">
                                    <h2 class="card-title mt-0"><b>{{$t('exercise.modale.segnala.argomentiCollegati')}}</b></h2>
                                    <p>{{$t('exercise.modale.segnala.nonAncoraClassificati')}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <h2 class="card-title mt-0"><b>{{$t('exercise.modale.segnala.sottotitolo')}}</b></h2>
                            <table class="table table-centered table-hover mb-0">
                                <tbody>
                                <tr class="cursorPointer" @click="reportingChoice = 0">
                                    <th scope="row" width="3%">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input cursorPointer" id="customCheck0" v-model="reportingChoice" value="0">
                                            <label class="custom-control-label cursorPointer" for="customCheck0"></label>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        {{$t('exercise.modale.segnala.segnalazioneZero')}}
                                    </th>
                                </tr>
                                <tr class="cursorPointer" @click="reportingChoice = 1">
                                    <th scope="row" width="3%">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input cursorPointer" id="customCheck1" v-model="reportingChoice" value="1">
                                            <label class="custom-control-label cursorPointer" for="customCheck1"></label>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        {{$t('exercise.modale.segnala.segnalazioneUno')}}
                                    </th>
                                </tr>
                                <tr class="cursorPointer" @click="reportingChoice = 2">
                                    <th scope="row" width="3%">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input cursorPointer" id="customCheck2" v-model="reportingChoice" value="2">
                                            <label class="custom-control-label cursorPointer" for="customCheck2"></label>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        {{$t('exercise.modale.segnala.segnalazioneDue')}}
                                    </th>
                                </tr>
                                <tr class="cursorPointer" @click="reportingChoice = 3">
                                    <th scope="row" width="3%">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input cursorPointer" id="customCheck3" v-model="reportingChoice" value="3">
                                            <label class="custom-control-label cursorPointer" for="customCheck3"></label>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        {{$t('exercise.modale.segnala.segnalazioneTre')}}
                                    </th>
                                </tr>
                                <tr class="cursorPointer" @click="reportingChoice = 4">
                                    <th scope="row" width="3%">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input cursorPointer" id="customCheck4" v-model="reportingChoice" value="4">
                                            <label class="custom-control-label cursorPointer" for="customCheck4"></label>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        {{$t('exercise.modale.segnala.segnalazioneQuattro')}}
                                    </th>
                                </tr>
                                <tr class="cursorPointer" @click="reportingChoice = 5">
                                    <th scope="row" width="3%">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input cursorPointer" id="customCheck5" v-model="reportingChoice" value="5">
                                            <label class="custom-control-label cursorPointer" for="customCheck5"></label>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        {{$t('exercise.modale.segnala.segnalazioneCinque')}}
                                    </th>
                                </tr>
                                <tr class="cursorPointer" @click="reportingChoice = 6">
                                    <th scope="row" width="3%">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" class="custom-control-input cursorPointer" id="customCheck6" v-model="reportingChoice" value="6">
                                            <label class="custom-control-label cursorPointer" for="customCheck6"></label>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        {{$t('exercise.modale.segnala.segnalazioneSei')}}
                                    </th>
                                </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.chiudi')}}</button>
                        <button type="button" :class="[((reportingChoice === undefined) ? 'cursorNotAllowed' : ''), 'btn btn-primary waves-effect waves-light']" :disabled="reportingChoice === undefined || currentQuiz.id === undefined || currentQuiz.id === ''" @click="sendReport()" data-dismiss="modal">{{$t('generale.invia')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal reporting -->

        <!-- Modal overlay pause -->
        <div id="modalOverlayPause" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalOverlayPauseLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-transparent border-0">
                    <div id="modal-body-overlay-pause" class="modal-body">
                        <div class="w-100 text-center">
                            <i class="mdi mdi-timer-off text-white" style="font-size: 200px;"></i>
                        </div>
                        <div class="w-100 text-center">
                            <h3 class="text-white">{{$t('exercise.modale.overlay.pausa')}}</h3>
                        </div>
                        <div class="w-100 text-center">
                            <h3 class="text-white">{{$t('exercise.modale.overlay.nonChiuderePerderaiProgressi')}}</h3>
                        </div>
                        <div class="w-100 text-center">
                            <button type="button" class="btn btn-primary waves-effect waves-light" @click="resumeTimer()" data-dismiss="modal">{{$t('exercise.modale.overlay.riprendi')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /Modal overlay pause -->

        <!-- Modal exercise error blocker -->
        <div id="modalExerciseErrorSendExercise" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalExerciseErrorSendExerciseLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalExerciseErrorSendExerciseLabel">{{$t('exercise.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-exercise-send-exercise" class="modal-body">
                        <p id="message-body-exercise-error-send-exercise"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" @click="$router.push({ name: 'CompetitionDetailWrapper', query: { type: computeUrlType() } })" data-dismiss="modal">{{$t('exercise.annullaEsci')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" @click="goToNextPage()" data-dismiss="modal">{{$t('exercise.riprovaConsegna')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal exercise error blocker -->

        <!-- Modal exercise explanation -->
        <div id="modalExerciseExplanation" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalExerciseExplanationLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalExerciseExplanationLabel">{{$t('exercise.modale.spiegazione.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-exercise-explanation" class="modal-body">
                        <p class="message-body-exercise-explanation">
                        </p>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal exercise explanation -->

        <!-- Modal exercise explanation premium -->
        <div id="modalExerciseExplanationPremium" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalExerciseExplanationPremiumLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalExerciseExplanationPremiumLabel">{{$t('exercise.modale.spiegazione.premium.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-exercise-explanation-premium" class="modal-body">
                        <p id="message-exercise-explanation-premium">{{$t('exercise.modale.spiegazione.premium.funzionalita')}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.SUBSCRIPTION })">{{$t('exercise.modale.limitQuiz.passaPremium')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal limit quiz -->

    </div>
</template>

<script>

    import $ from 'jquery'
    import 'ion-rangeslider'
    import { ImageViewer, FullScreenViewer } from 'iv-viewer';
    import _ from "lodash";
    import {MODULES} from "@/store/modules-enum";
    import {mapActions, mapGetters} from 'vuex'
    import {COMPETITION_GETTERS} from "@/store/competition/getters-enum";
    import {competitionDetailTabsEnums, themeEnums} from "@/helpers/enums";
    import EsercitazioneManager from "@/lib/ConcorsandoWasm/EsercitazioneManager";
    import ImagesClient from "@/lib/ConcorsandoWasm/ImagesClient";
    import { Errors } from "@/lib/ConcorsandoWasm/Messages";
    import CustomClient from "@/lib/CustomConcorsandoClient/api/CustomClient";
    import {AUTH_GETTERS} from "@/store/auth/getters-enum";
    import Swal from 'sweetalert2'
    import {PROFILE_ACTIONS} from "@/store/profile/actions-enum";
    import { ContentLoader } from 'vue-content-loader'
    import { Timer } from 'easytimer.js';
    import {EsercitazioneEnum, ModalitaEsercitazioneEnum} from '@/lib/ConcorsandoWasm/Constants'
    import { routesEnums } from "@/helpers/enums";
    import PercorsiEsercitazioneManager from "@/lib/ConcorsandoWasm/PercorsiEsercitazioneManager";
    import {PATH_GETTERS} from "@/store/path/getters-enum";
    import {alphaToNum} from "@/helpers/utilities";
    import {COMPETITION_ACTIONS} from "@/store/competition/actions-enum";
    import {autoNextEsercitazioneDelay, autoNextSimulazioneDelay} from "@/config";
    import {UTILITY_ACTIONS_DISPATCH} from "@/store/utility/actions-enum";
    import firebase from "firebase/app";

    const imagesClient = ImagesClient.instance
    const esercitazioneManager = EsercitazioneManager.instance
    const percorsiEsercitazioneManager = PercorsiEsercitazioneManager.instance
    const customClient = CustomClient.instance

    export default {
        name: "Exercise",
        components: {
            ContentLoader
        },
        data () {
            return {
                isPath: false,
                tipoEsercitazioneManager: undefined,
                speech: window.speechSynthesis,
                speechUtterance: new window.SpeechSynthesisUtterance(),
                routesNames: routesEnums,
                timer: undefined,
                quizError: false,
                isImageError: false,
                isLoading: false,
                isImageLoaded: false,
                urlTypeEnum: competitionDetailTabsEnums,
                panZoom: undefined,
                questionNumber: 1,
                currentQuizMeta: {},
                timeoutRequest: undefined,
                timeoutExceed: undefined,
                answer: undefined,
                answered: false,
                currentQuiz: {},
                reportingChoice: undefined,
                animated: true,
                setupArrowsKeyboardFunction: null,
                correctAnswers: 0,
                wrongAnswers: 0,
                countdownTimer: "",
                isPaused: false,
                exerciseEnum: EsercitazioneEnum,
                exerciseMode: ModalitaEsercitazioneEnum,
                isNotAnswered: false,
                isSpeechPaused: false,
                isLastQuiz: false,
                speechTimeout: undefined,
                popularAnswers: undefined,
            }
        },
        computed: {
            ...mapGetters(MODULES.PATH, [PATH_GETTERS.SELECTED_PATHS]),
            ...mapGetters(MODULES.COMPETITION, [COMPETITION_GETTERS.SELECTED_COMPETITIONS]),
            ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE]),
            showAutoNext: function () {
                return this.isEseguita();
            },
            autoNext: {
                get () {
                    if (this.isEseguita()) {
                        return this.$store.state.utility.autoNext;
                    }
                    else if (this.isSimulazione()) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
                set (newVal) {
                    if (this.isEseguita()) {
                        if (newVal === true) {
                            this.showModalMessage(this.$t("exercise.modale.autoNextAttivato"))
                        }
                        this.$store.dispatch(UTILITY_ACTIONS_DISPATCH.SET_AUTO_NEXT, newVal);
                    }
                },
            }
        },
        beforeDestroy() {
            clearTimeout(this.speechTimeout)
            this.speechUtterance.onend = null
            window.speechSynthesis.cancel()
            this.stopTimer()
            const context = this
            if (this.computeUrlType() === this.urlTypeEnum.EXERCISE) {
                this.tipoEsercitazioneManager.clear()
            }
            clearTimeout(context.timeoutRequest)
            clearTimeout(context.timeoutExceed)

            document.removeEventListener('keydown', context.setupArrowsKeyboardFunction)
        },
        mounted() {
            this.verifyModeOfExercise()
            this.backToTop()
            this.verifyUUID()
        },
        methods: {
            ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.UPDATE_USER, PROFILE_ACTIONS.UPDATE_LEVEL]),
            ...mapActions(MODULES.COMPETITION, [COMPETITION_ACTIONS.GET_EXPLANATION]),
            
            goToOrigin () {
                this.$emit('is-components-ready', 'exercise', true)
                if (this.isPath) {
                    this.$router.push({ name: routesEnums.PATH_DETAIL_WRAPPER })
                } else {
                    this.$router.push({ name: routesEnums.COMPETITION_DETAIL_WRAPPER, query: { type: this.computeUrlType() } })
                }
            },
            verifyModeOfExercise () {
                if (this.$route.params.mode !== undefined && this.$route.params.mode === 'path' || this.$route.params.type === 'materia') {
                    this.isPath = true
                    this.tipoEsercitazioneManager = percorsiEsercitazioneManager
                } else {
                    this.tipoEsercitazioneManager = esercitazioneManager
                }
            },
            isEseguita () {
                return this.currentQuizMeta && this.currentQuizMeta.tipo_esercitazione === EsercitazioneEnum.ESERCITAZIONE && this.currentQuizMeta.modalita_esercitazione === ModalitaEsercitazioneEnum.ESEGUITA
            },
            isSimulazione () {
                return this.currentQuizMeta && [EsercitazioneEnum.PROVA_PERSONALIZZATA, EsercitazioneEnum.SIMULAZIONE].includes(this.currentQuizMeta.tipo_esercitazione);
            },
            verifyUUID () {
                this.$emit('is-components-ready', 'exercise', false)
                this.isLoading = true
                let elements = undefined
                if (this.isPath) {
                    elements = this.selectedPaths
                } else {
                    elements = this.selectedCompetitions
                }
                const selectedElement = _.cloneDeep(_.find(elements, function (c) {
                    return c.selected
                }))
                if (selectedElement !== undefined && selectedElement !== null) {
                    if (this.$route.params.uuid !== undefined && this.$route.params.uuid !== "") {
                        this.getExerciseMeta()
                    } else {
                        this.goToOrigin()
                    }
                } else {
                    this.$emit('is-components-ready', 'exercise', true)
                    this.$router.push({ name: routesEnums.HOME })
                }
            },
            /** Si scarica tutti i dati dell'esercitazione, e fa iniziale il flusso.
             * Vengono registrati tutti i callback che faranno avanzare l'esercitazione e viene scaricata la prima domanda
             */
            getExerciseMeta () {
                this.tipoEsercitazioneManager.getEsercitazioneMeta()
                    .then((res) => {
                        this.currentQuizMeta = res
                        if (this.currentQuizMeta.n_quiz === 0 || this.currentQuizMeta.n_quiz === undefined) {
                            this.goToOrigin()
                        } else {
                            this.getQuestion()
                                .then((quiz) => {
                                    this.currentQuiz = _.cloneDeep(quiz)
                                    if (quiz.url_img !== undefined && quiz.url_img !== null) {
                                        imagesClient.get(quiz.url_img)
                                            .then((img) => {
                                                this.currentQuiz.url_img = img
                                                this.isImageLoaded = true
                                            })
                                            .catch(() => {
                                                this.isImageError = true
                                            })
                                            .finally(() => {
                                                this.init()
                                            })
                                    } else {
                                        this.init()
                                    }
                                })
                                .catch(err => {
                                    this.init()
                                    this.verifyTypeError(err)
                                })
                        }
                    })
            },
            getQuestion (n_domanda_param) {
                this.isNotAnswered = false
                clearTimeout(this.timeoutExceed)
                /* Casi da trattare:
                  *   1) il quiz è quello giusto, e non è già stato settato
                  *   2) il quiz è quello giusto, ed è già stato settato
                  *   3) il quiz non è quello giusto
                  *   4) errore RATE_LIMIT_EXCEEDED
                  *   5) altri errori
                  */
                const context = this
                const n_domanda = n_domanda_param === undefined ? this.questionNumber : n_domanda_param;
                if (n_domanda !== this.questionNumber) {
                    return Promise.reject({code: "QUIZ_DIVERSO"})
                }
                return this.tipoEsercitazioneManager.getQuiz((n_domanda - 1))
                    .then((res) => {
                        if (n_domanda === context.questionNumber) {
                            if (res.dettaglio_risposta_gettonata !== undefined) {
                                context.popularAnswers = res.dettaglio_risposta_gettonata
                            } else {
                                context.popularAnswers = undefined
                            }
                            // QUI SAPPIAMO CHE LA DOMANDA è quella giusta
                            if (context.currentQuiz && context.currentQuiz.id === res.id) {
                                // Questo significa che la domanda è già stata settata, e non dobbiamo fare niente
                                return Promise.reject({code: "QUIZ_GIA_SETTATO"}) // Caso 2
                            }
                            if (res.selectedAnswer !== undefined) {
                                context.answered = true
                                context.answer = res.selectedAnswer
                            } else {
                                if (context.currentQuizMeta.tipo_esercitazione !== undefined && context.currentQuizMeta.tipo_esercitazione === context.exerciseEnum.RIVEDI_PROVA) {
                                    context.isNotAnswered = true
                                    context.answered = true
                                } else if (context.currentQuizMeta.tipo_esercitazione === context.exerciseEnum.ASSEGNA_RISPOSTE && res.risposta_gettonata !== undefined && res.risposta_gettonata !== '') {
                                    context.answer = res.risposta_gettonata
                                }
                            }
                            if (context.currentQuizMeta.modalita_esercitazione !== undefined && context.currentQuizMeta.modalita_esercitazione === context.exerciseMode.LETTA) {
                                context.answered = true
                                context.answer = _.find(res.risposte, (r) => r.esatta).id
                            }
                            return res; // Caso 1
                        } else {
                            return Promise.reject({code: "QUIZ_DIVERSO"}) // Caso 3
                        }
                    })
                    .catch(error => {
                        if (n_domanda === context.questionNumber) {
                            return Promise.reject(error)
                        } else {
                            return Promise.reject({code: "QUIZ_DIVERSO"})
                        }
                    })
            },
            computeUrlType () {
                if (this.$route.params.type === competitionDetailTabsEnums.EXERCISE || this.$route.params.type === 'materia') {
                    return competitionDetailTabsEnums.EXERCISE
                } else if (this.$route.params.type === competitionDetailTabsEnums.PERSONALIZED_TRIAL) {
                    return competitionDetailTabsEnums.PERSONALIZED_TRIAL
                } else if (this.$route.params.type === competitionDetailTabsEnums.CHALLENGE) {
                    return competitionDetailTabsEnums.CHALLENGE
                }
            },
            init() {
                const context = this
                $( document ).ready(function() {
                    $('[data-toggle="tooltip"]').tooltip()

                    if (context.currentQuiz !== undefined && context.currentQuiz !== null && context.currentQuiz.url_img !== '' && context.currentQuiz.url_img !== null && context.currentQuiz.url_img !== undefined && context.isImageError === false) {
                        const container = document.getElementById('image-container')
                        context.panZoom = new ImageViewer(container, { snapView: false })
                        context.panZoom.load(context.currentQuiz.url_img, context.currentQuiz.url_img)
                    }

                    context.setupArrowsKeyboardFunction = function setupKeyboard(event) {
                        if (context.isSomeModalOpen() === undefined) {
                            if (event.key === 'ArrowLeft') {
                                if (context.questionNumber > 1)
                                    context.updateProgress('backward')
                            } else if (event.key === 'ArrowRight') {
                                if (context.questionNumber < context.currentQuizMeta.n_quiz)
                                    context.updateProgress('forward')
                            } else {
                                let risposta = undefined
                                let key = !isNaN(event.key) ? event.key : alphaToNum[event.key]
                                if (context.currentQuiz.risposte.length >= key) {
                                    risposta = context.currentQuiz.risposte[(key - 1)]
                                }
                                if (risposta !== undefined) {
                                    context.selectAnswer(risposta.id, risposta.esatta)
                                }
                            }
                        }
                    }

                    document.addEventListener('keydown', context.setupArrowsKeyboardFunction);

                    context.isLoading = false
                    $('#quiz-content').css('visibility', 'visible')

                    if (context.computeUrlType() !== context.urlTypeEnum.EXERCISE && context.currentQuizMeta.tipo_esercitazione !== context.exerciseEnum.RIVEDI_PROVA) {
                        context.convertStringToSecondsAndInitTimer()
                    }

                    context.$emit('is-components-ready', 'exercise', true)

                    if (context.currentQuiz !== undefined && context.currentQuiz !== null)
                        context.startSpeech(true)
                })
            },
            startSpeech (init) {
                clearTimeout(this.speechTimeout)
                if (((this.currentQuizMeta.modalita_esercitazione === this.exerciseMode.ASCOLTATA && !window.speechSynthesis.paused)
                    || this.currentQuizMeta.modalita_esercitazione === this.exerciseMode.ASCOLTATA && window.speechSynthesis.paused && !window.speechSynthesis.speaking)
                    && !this.isLastQuiz) {
                    this.isSpeechPaused = false
                    this.speechTimeout = setTimeout(() => {
                        const answer = _.find(this.currentQuiz.risposte, (risposta) => risposta.esatta)
                        this.speechUtterance.text = (init !== undefined && init) ? "Avvio lettura. " + this.currentQuiz.domanda : this.currentQuiz.domanda
                        window.speechSynthesis.speak(this.speechUtterance)
                        this.speechUtterance.onend = () => {
                            this.speechUtterance.text = answer.testo
                            this.speechTimeout = setTimeout(() => {
                                window.speechSynthesis.speak(this.speechUtterance)
                                this.speechUtterance.onend = null
                                this.speechUtterance.onend = () => {
                                    if (this.currentQuizMeta.n_quiz === this.questionNumber
                                        || this.currentQuizMeta.n_quiz === 0
                                        || this.questionNumber > this.currentQuizMeta.n_quiz
                                        || this.currentQuizMeta.n_quiz === undefined) {
                                        this.isLastQuiz = true
                                        this.isSpeechPaused = true
                                    } else {
                                        this.updateProgress('forward')
                                    }
                                }
                            }, 1000)
                        }
                    }, 1000)
                }
            },
            speechPauseOrResume () {
                if (window.speechSynthesis.paused) {
                    window.speechSynthesis.resume()
                    this.speechUtterance.onend = null
                    window.speechSynthesis.cancel()
                    setTimeout(() => {
                        this.isSpeechPaused = false
                        this.startSpeech()
                    }, 500)
                } else {
                    if (window.speechSynthesis.speaking) {
                        this.speechUtterance.onend = null
                        window.speechSynthesis.pause()
                        this.isSpeechPaused = true
                    } else {
                        this.isLastQuiz = false
                        this.startSpeech()
                    }
                }
            },
            convertStringToSecondsAndInitTimer () {
                if (this.currentQuizMeta.tempo_tot !== null && this.currentQuizMeta.tempo_tot !== undefined && this.currentQuizMeta.tempo_tot !== "") {
                    this.timer = new Timer()
                    const time = this.currentQuizMeta.tempo_tot
                    const hms = time.split(':')
                    const seconds = (+hms[0]) * 60 * 60 + (+hms[1]) * 60 + (+hms[2])
                    this.initTimer(seconds)
                } else {
                    this.showModalErrorBlocker('exercise.modale.errore.tempoTot')
                }
            },
            initTimer (seconds) {
                if (seconds > 0) {
                    this.timer.start({countdown: true, startValues: {seconds: seconds}});
                    this.countdownTimer = this.timer.getTimeValues().toString()
                    this.timer.addEventListener('secondsUpdated', (e) => {
                        this.countdownTimer = this.timer.getTimeValues().toString()
                    });
                    this.timer.addEventListener('targetAchieved', (e) => {
                        this.goToNextPage()
                    });
                } else {
                    this.showModalErrorBlocker('exercise.modale.errore.secondi')
                }
            },
            pauseTimer() {
                this.isPaused = true
                this.timer.pause()
                $('#modalOverlayPause').modal({backdrop: 'static', keyboard: false})
                $('#overlay').show()
            },
            resumeTimer () {
                $('#overlay').hide()
                this.isPaused = false
                this.timer.start()
            },
            resetPanZoom () {
                this.panZoom.resetZoom()
            },
            openImageQuiz () {
                const viewer = new FullScreenViewer();
                viewer.show(this.currentQuiz.url_img, this.currentQuiz.url_img);
            },
            updateProgress (type, data) {
                $('.message-body-exercise-explanation').html("")
                this.speechUtterance.onend = null
                window.speechSynthesis.cancel()
                clearTimeout(this.timeoutRequest)
                clearTimeout(this.timeoutExceed)
                $('#quiz-content').hide()
                this.quizError = false
                this.isLoading = true
                this.animated = true
                this.$nextTick(() => {
                    $('#text-placeholder > .vue-content-placeholders-text__line').css('margin-bottom', '0')
                })
                this.questionNumber = this.setupNewValueProgressBar(type, data)
                $('#quiz-content').hide()
                const n_domanda = this.questionNumber;
                this.timeoutRequest = setTimeout(() => {
                    this.answer = undefined
                    this.answered = false
                    this.getQuestion(n_domanda)
                        .then((quiz) => {
                            this.setupSelectedQuiz(_.cloneDeep(quiz))
                        })
                        .catch(err => {
                            this.verifyTypeError(err, n_domanda)
                        })
                }, 200)
            },
            setupNewValueProgressBar (type, data) {
                let value = 0
                if (type !== null) {
                    value = (type === 'forward') ? (this.questionNumber + 1) : (this.questionNumber - 1)
                    if (value < 1) {
                        value = 1
                        this.questionNumber = 1
                    }
                } else {
                    value = data
                }
                return value
            },
            setupSelectedQuiz (quiz) {
                this.isImageLoaded = false
                this.isImageError = false
                this.quizError = false
                this.currentQuiz = quiz
                if (quiz.url_img !== undefined && quiz.url_img !== null) {
                    imagesClient.get(quiz.url_img)
                        .then((img) => {
                            this.currentQuiz.url_img = img
                            this.isImageLoaded = true
                        })
                        .catch(() => {
                            this.isImageError = true
                        })
                        .finally(() => {
                            this.isLoading = false
                            $('#quiz-content').show()
                            $('#quiz-content').css('visibility', 'hidden')
                            this.$nextTick(() => {
                                if (this.isImageError === false) {
                                    this.recreateImageViewer()
                                }
                                $('#quiz-content').css('visibility', 'visible')
                            })
                            clearTimeout(this.timeoutExceed)
                            clearTimeout(this.timeoutRequest)
                            this.reportingChoice = undefined
                        })
                } else {
                    this.isLoading = false
                    $('#quiz-content').show()
                    $('#quiz-content').css('visibility', 'visible')
                    this.reportingChoice = undefined
                    clearTimeout(this.timeoutExceed)
                    clearTimeout(this.timeoutRequest)
                    if (!window.speechSynthesis.paused) {
                        clearTimeout(this.speechTimeout)
                        window.speechSynthesis.cancel()
                        this.startSpeech()
                    }
                }
            },
            recreateImageViewer () {
                if (this.panZoom !== undefined) {
                    this.panZoom.destroy()
                }
                const container = document.getElementById('image-container')
                this.panZoom = new ImageViewer(container, { snapView: false })
                this.panZoom.load(this.currentQuiz.url_img, this.currentQuiz.url_img)
            },
            verifyTypeError (error, n_domanda) {
                if (error !== undefined && error.code !== undefined) {
                    if (error.code === Errors.RATE_LIMIT_EXCEEDED) {
                        clearTimeout(this.timeoutRequest)
                        clearTimeout(this.timeoutExceed)
                        let retryOn = (error.retry_on !== undefined) ? (error.retry_on - Date.now()) : 5000
                        retryOn = (retryOn > 0) ? retryOn : 0
                        this.timeoutExceed = setTimeout(() => {
                            this.getQuestion(n_domanda)
                                .then((quiz) => {
                                    this.setupSelectedQuiz(_.cloneDeep(quiz))
                                })
                                .catch(err => {
                                    this.verifyTypeError(err)
                                })
                        }, retryOn)
                    } else {
                        if (error.code === 'QUIZ_GIA_SETTATO') {
                            this.isLoading = false
                            $('#quiz-content').show()
                        }
                        else if (error.code === "QUIZ_DIVERSO") {
                            // Non c'è niente da fare in questo caso
                        }
                        else {
                            this.showModalError('exercise.modale.errore.domandaNonTrovata')
                            this.isLoading = false
                            this.animated = false
                            this.quizError = true
                            this.currentQuiz.id = (error.quizStats !== undefined && error.quizStats.id_domanda !== undefined) ? error.quizStats.id_domanda : undefined
                            this.$emit('is-components-ready', 'exercise', true)
                        }
                    }
                } else {
                    this.$emit('is-components-ready', 'exercise', true)
                    this.goToOrigin()
                }
            },
            computeMessageToShow () {
                const typeOfUrl = this.computeUrlType()
                if (typeOfUrl === competitionDetailTabsEnums.EXERCISE) {
                    return this.$t('exercise.esercitazione')
                } else if (typeOfUrl === competitionDetailTabsEnums.PERSONALIZED_TRIAL && this.currentQuizMeta.tipo_esercitazione !== this.exerciseEnum.RIVEDI_PROVA) {
                    return this.$t('exercise.prova')
                } else if (typeOfUrl === competitionDetailTabsEnums.PERSONALIZED_TRIAL && this.currentQuizMeta.tipo_esercitazione === this.exerciseEnum.RIVEDI_PROVA) {
                    return this.$t('exercise.rivediProva')
                } else if (typeOfUrl === competitionDetailTabsEnums.CHALLENGE && this.currentQuizMeta.tipo_esercitazione !== this.exerciseEnum.RIVEDI_PROVA) {
                    return this.$t('exercise.simulazione')
                } else if (typeOfUrl === competitionDetailTabsEnums.CHALLENGE && this.currentQuizMeta.tipo_esercitazione === this.exerciseEnum.RIVEDI_PROVA) {
                    return this.$t('exercise.rivediProva')
                }
            },
            selectAnswer (rispostaId, rispostaEsatta) {
                if (this.currentQuizMeta.tipo_esercitazione !== this.exerciseEnum.RIVEDI_PROVA && this.currentQuizMeta.modalita_esercitazione !== this.exerciseMode.LETTA && this.currentQuizMeta.modalita_esercitazione !== this.exerciseMode.ASCOLTATA) {
                    if (this.answered === undefined || this.answered === null || this.answered === false) {
                        this.setTheSelectAnswer(rispostaId, rispostaEsatta)
                    } else {
                        if (this.computeUrlType() === this.urlTypeEnum.EXERCISE) {
                            return false
                        } else {
                            this.setTheSelectAnswer(rispostaId, rispostaEsatta)
                        }
                    }
                }
            },
            setTheSelectAnswer (rispostaId, rispostaEsatta) {
                this.answer = rispostaId
                this.tipoEsercitazioneManager.selectQuizAnswer(this.currentQuiz.id, rispostaId)
                    .finally(() => {
                        if (this.currentQuizMeta.tipo_esercitazione !== this.exerciseEnum.ASSEGNA_RISPOSTE) {
                            if (this.profile.livello > 1) {
                                this.updateLevel(this.profile.livello + 1)
                                    .finally(() => {
                                        let skipAutoNext = false
                                        this.sendAnalytics();
                                        if (this.profile.livello > 501) {
                                            // Se l'utente ha esaurito i quiz non facciamo l'autoNext
                                            skipAutoNext = true
                                            $('#modalLimitQuiz').modal({backdrop: 'static', keyboard:false})
                                        }
                                        this.updateQuizCounter(rispostaId, rispostaEsatta, skipAutoNext)
                                    })
                            } else {
                                this.updateQuizCounter(rispostaId, rispostaEsatta)
                            }
                        }
                    })
            },
            sendAnalytics(){
                switch (this.profile.livello) {
                    case 22:
                        this.analytics('user_level_20')
                        break
                    case 102:
                        this.analytics('user_level_100')
                        break
                    case 252:
                        this.analytics('user_level_250')
                        break
                    case 402:
                        this.analytics('user_level_400')
                        break
                    case 502:
                        this.analytics('web_limit')
                        break

                }
            },
            analytics(key){
                window.fbq('trackCustom',key)
                firebase.analytics().logEvent(key)
            },
            updateQuizCounter (rispostaId, rispostaEsatta, skipAutoNext = false) {
                this.answered = true
                if (this.computeUrlType() === this.urlTypeEnum.EXERCISE) {
                    if (this.answered && rispostaEsatta) {
                        this.correctAnswers++
                        if (!skipAutoNext) {
                            this.performAutoNext()
                        }
                    } else if (this.answered && rispostaId === this.answer && rispostaEsatta === false) {
                        this.wrongAnswers++
                    }
                }
                else {
                    if (!skipAutoNext) {
                        this.performAutoNext()
                    }
                }
            },
            performAutoNext () {
                if (!this.autoNext || (!this.isEseguita() && !this.isSimulazione())) {
                    return;
                }
                let delay = 1000;
                let cb = () => {}
                if (this.isEseguita()) {
                    delay = autoNextEsercitazioneDelay;
                    if (this.currentQuizMeta.n_quiz === undefined || this.questionNumber >= this.currentQuizMeta.n_quiz) {
                        cb = () => $("#modalAutoNextLastQuiz").modal({backdrop: 'static', keyboard: false})
                    }
                    else {
                        cb = () => this.updateProgress("forward")
                    }
                }
                else {
                    delay = autoNextSimulazioneDelay;
                    const nextQuizIx = this.tipoEsercitazioneManager.getNextQuizIx(this.questionNumber - 1)
                    if (nextQuizIx !== null) {
                        cb = () => this.updateProgress(null, nextQuizIx  + 1)
                    }
                    else {
                        cb = () => this.goToNextPage()
                    }
                }

                setTimeout(cb, delay);
            },
            computeTheAnswer (rispostaId, rispostaEsatta) {
                if (this.computeUrlType() === this.urlTypeEnum.EXERCISE || this.currentQuizMeta.tipo_esercitazione === this.exerciseEnum.RIVEDI_PROVA) {
                    if (this.answered && rispostaEsatta) {
                        return 'bg-soft-success'
                    } else if (this.answered && rispostaId === this.answer && rispostaEsatta === false) {
                        return 'bg-soft-danger'
                    }
                }
                return ''
            },
            computeTheModeAndTheAnswer () {
                if (this.computeUrlType() === this.urlTypeEnum.EXERCISE) {
                    return this.answered;
                } else if (this.currentQuizMeta.tipo_esercitazione === this.exerciseEnum.RIVEDI_PROVA) {
                    return true
                }
                return false
            },
            openModalReport () {
                this.reportingChoice = undefined
                $('#modalReporting').modal('show')
            },
            sendReport () {
                if (this.reportingChoice !== undefined) {
                    this.$emit('is-components-ready', 'exercise', false)
                    customClient.sendSegnalazione({
                        id_user: this.profile.id_utente_concorsando,
                        id_domanda: this.currentQuiz.id,
                        rcode: this.reportingChoice
                    })
                        .then(() => {
                            this.$emit('is-components-ready', 'exercise', true)
                            $('#modalReporting').modal('hide')
                            this.reportingChoice = undefined
                            Swal.fire({
                                title: this.$t('exercise.modale.segnala.benFatto'),
                                text: this.$t('exercise.modale.segnala.inviata'),
                                icon: 'success',
                                confirmButtonText: this.$t('generale.ok')
                            })
                        })
                        .catch(() => {
                            this.reportingChoice = undefined
                            this.$emit('is-components-ready', 'exercise', true)
                            $('#modalReporting').modal('hide')
                            $('#modalReportingError').modal('show')
                        })
                }
            },
            openWiki () {
                window.open(this.currentQuiz.url_wiki, '_blank', 'noopener');
            },
            addFavorites () {
                this.tipoEsercitazioneManager.changeFavorites(this.currentQuiz.id, (!this.currentQuiz.preferiti))
                    .then(() => {
                        this.currentQuiz.preferiti = (!this.currentQuiz.preferiti)
                    })
            },
            backToTop () {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            goToNextPage () {
                $('#modalReporting').modal('hide')
                $('#modalConfirm').modal('hide')
                $('#modalConfirmDeliver').modal('hide')
                this.stopTimer()
                if (this.computeUrlType() !== this.urlTypeEnum.EXERCISE) {
                    this.$emit('is-components-ready', 'exercise', false)
                    this.tipoEsercitazioneManager.concludiProva()
                        .then((uuid) => {
                            this.$emit('is-components-ready', 'exercise', true)
                            this.$router.push({ name: routesEnums.SUMMARY, params: { uuid: uuid, type: this.computeUrlType() } })
                        })
                        .catch(() => {
                            this.showModalErrorSendTrail('exercise.modale.errore.concludiProva')
                        })
                } else {
                    this.goToOrigin()
                }
            },
            showModalError (message) {
                $('.loading').hide()
                const context = this
                $('#modalExerciseError').modal('show')
                $('#message-body-exercise-error').html(context.$t(message))
            },
            showModalMessage (message) {
                $('#modalMessage').modal('show')
                $('#message-body-message').html(message)
            },
            closeModalMessage () {
                $('#message-body-message').html("")
            },
            showModalErrorBlocker (message) {
                this.$emit('is-components-ready', 'exercise', true)
                const context = this
                $('#modalExerciseErrorBlocker').modal({backdrop: 'static', keyboard: false})
                $('#message-body-exercise-error-blocker').html(context.$t(message))
            },
            computeExerciseMode () {
                return (this.currentQuizMeta.modalita_esercitazione === this.exerciseMode.LETTA) || (this.currentQuizMeta.tipo_esercitazione === this.exerciseEnum.RIVEDI_PROVA)
            },
            showModalErrorSendTrail (message) {
                this.$emit('is-components-ready', 'exercise', true)
                const context = this
                $('#modalExerciseErrorSendExercise').modal({backdrop: 'static', keyboard: false})
                $('#message-body-exercise-error-send-exercise').html(context.$t(message))
            },
            stopTimer () {
                if (this.timer !== undefined) {
                    this.timer.stop()
                    this.timer.removeEventListener('secondsUpdated')
                    this.timer.removeEventListener('targetAchieved')
                }
            },
            isSomeModalOpen () {
                const modalsStatus = [
                    ($("#modalConfirm").data('bs.modal') || {})._isShown,
                    ($("#modalAutoNextLastQuiz").data('bs.modal') || {})._isShown,
                    ($("#modalMessage").data('bs.modal') || {})._isShown,
                    ($("#modalLimitQuiz").data('bs.modal') || {})._isShown,
                    ($("#modalConfirmDeliver").data('bs.modal') || {})._isShown,
                    ($("#modalExerciseError").data('bs.modal') || {})._isShown,
                    ($("#modalExerciseErrorBlocker").data('bs.modal') || {})._isShown,
                    ($("#modalReportingError").data('bs.modal') || {})._isShown,
                    ($("#modalReporting").data('bs.modal') || {})._isShown,
                    ($("#modalOverlayPause").data('bs.modal') || {})._isShown,
                    ($("#modalExerciseErrorSendExercise").data('bs.modal') || {})._isShown
                ]
                return _.find(modalsStatus, (mS) => mS !== undefined && mS)
            },
            computeWhereToGo () {
                if (this.currentQuizMeta.tipo_esercitazione === this.exerciseEnum.RIVEDI_PROVA) {
                    this.goToNextPage()
                } else {
                    this.goToOrigin()
                }
            },
            computeColor (type) {
                if (localStorage.getItem('theme') === themeEnums.DARK) {
                    if (type === 'primary') {
                        return '#1f282e'
                    } else {
                        return '#0e1215'
                    }
                } else {
                    if (type === 'primary') {
                        return '#f5f5f5'
                    } else {
                        return '#dbdbdb'
                    }
                }
            },
              openExplanation (idQuiz) {
                if (this.profile.livello <= 1) {
                    $('.loading').show()
                    this.getExplanation(idQuiz)
                        .then((res) => {
                            $('.loading').hide()
                            const linkReplaced = res.replaceAll('http://spiegazioni.concorsando.it', 'https://spiegazioni.concorsando.it')
                            const parser = new DOMParser();
                            const doc = parser.parseFromString(linkReplaced, "text/html")
                            const boldPattern = /\*\*(.*?)\*\*/g;
                            const contentElement = doc.querySelector('.entry-content');
                            let textContent = contentElement.innerHTML;
                            const newTextContent = textContent.replace(boldPattern, '<strong>$1</strong>');
                            $('.message-body-exercise-explanation').html(newTextContent)
                            $('#modalExerciseExplanation').modal('show')
                        })
                        .catch(() => {
                            this.showModalError('exercise.modale.errore.spiegazione')
                        })
                } else {
                    $('#modalExerciseExplanationPremium').modal('show')
                }
            }
        }
    }

  
</script>

<style scoped lang="css">
    @import '~ion-rangeslider/css/ion.rangeSlider.min.css';
    @import '~iv-viewer/dist/iv-viewer.css';
</style>

<style scoped lang="scss">
    html.theme-dark {
        #footer-container {
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            background-color: #27333a;
        }
    }

    html.theme-light {
        #footer-container {
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            background-color: white;
        }
    }

    html.theme-light, html.theme-dark {
        .mt-01 {
            margin-top: .18rem !important;
        }

        #overlay {
            position: absolute; /* Sit on top of the page content */
            display: none; /* Hidden by default */
            width: 100%; /* Full width (cover the whole page) */
            height: 100%; /* Full height (cover the whole page) */
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.5); /* Black background with opacity */
            z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
            cursor: pointer; /* Add a pointer on hover */
        }

        .blurred {
            filter: blur(5px);
        }
    }
</style>
